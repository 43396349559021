import urlcat from 'urlcat';
import { fetchWrapped, PostOptions, applyModifiers, addXCartSession, addUserJWT, addContentTypeJson } from '.';

import { cartApi, cartApiV1, cartGraphQLApi } from './endpoints';

const addProductToCart = async (productId, shopId, merchantPid, cartSession, userJWT) =>
  await fetchWrapped(
    urlcat(cartApi, '/carts/add-product/'),
    new PostOptions({
      bodyObject: { product: productId, sid: shopId, spid: merchantPid },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const removeProductFromCart = async (productId, shopId, merchantPid, cartSession, userJWT) =>
  await fetchWrapped(
    urlcat(cartApi, '/carts/remove-product/'),
    new PostOptions({
      bodyObject: { product: productId, all: true, sid: shopId, spid: merchantPid },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const removeAllProductsFromCart = async (openCarts, cartSession, userJWT) => {
  const products = [];
  openCarts.forEach((cart) => {
    if (cart.products) {
      cart.products.forEach((product) => {
        products.push({ ...product, shopId: cart.shop?.id, merchantPid: product?.merchant_product_id });
      });
    }
  });
  await Promise.all(
    products.map((product) => {
      return fetchWrapped(
        urlcat(cartApi, '/carts/remove-product/'),
        new PostOptions({
          bodyObject: { product: product.id, all: true, sid: product.shopId, spid: product.merchantPid },
          headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
        })
      );
    })
  );
};

const decrementProductQuantity = async (productId, shopId, merchantPid, cartSession, userJWT) =>
  await fetchWrapped(
    urlcat(cartApi, '/carts/remove-product/'),
    new PostOptions({
      bodyObject: { product: productId, sid: shopId, spid: merchantPid },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const openCarts = async (cartSession, userJWT) =>
  await fetchWrapped(
    urlcat(cartApiV1, '/carts/open/'),
    new PostOptions({ headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]) })
  );

const getDeliveryAddresses = async (userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/delivery_addresses/'),
    new PostOptions({ headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]) })
  );

const getOrderDetails = async (userJWT, cartSession, order_id) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/order_overview/'),
    new PostOptions({
      bodyObject: { order_id },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const updateDeliveryAddress = async (
  addressId = null,
  addressName,
  addressData,
  isDefault = false,
  deleted = false,
  userJWT,
  cartSession
) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/update_delivery_address/'),
    new PostOptions({
      bodyObject: {
        public_id: addressId,
        name: addressName,
        address: addressData,
        default: isDefault,
        deleted,
      },
      headers: applyModifiers({}, [addContentTypeJson, addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const addDeliveryAddress = (addressName, addressData, isDefault, userJWT, cartSession) =>
  updateDeliveryAddress(null, addressName, addressData, isDefault, false, userJWT, cartSession);

const selectDeliveryAddress = async (id, userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/delivery_address/'),
    new PostOptions({
      bodyObject: { id, attribute: 'delivery_address' },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const selectBillingAddress = async (id, userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/delivery_address/'),
    new PostOptions({
      bodyObject: { id, attribute: 'billing_address' },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const selectShippingMethod = async (methodId, shopId, userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/shipping_method/'),
    new PostOptions({
      bodyObject: { id: methodId, shop_id: shopId },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

const toGraphQlQuery = (query) => query.replaceAll('\n', '').replaceAll(/\s+/g, ' ');

const getDeliveryOptions = async (userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(cartApi, '/orders/delivery_options/'),
    new PostOptions({ headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]) })
  );

const getOrdersList = async (userJWT) =>
  await fetchWrapped(
    cartGraphQLApi,
    new PostOptions({
      bodyObject: {
        query: `query OrdersList {
            orders {
              id
              returnTill
              shopName
              shopSdp
              shopId
              status
              paidAt
              deliveryAddress {
                name
              }
              products {
                perItem
                groupId
                merchantProductId
                name
                picture
                quantity
                returnableQuantity
                size
                pdpPath
              }
              shippingMethod,
              shipments {
                trackingCode,
                link,
                service,
                updates {
                  message,
                  id,
                  receivedAt,
               }
              }
            }
          }`,
      },
      headers: applyModifiers({}, [addContentTypeJson, addUserJWT(userJWT)]),
    })
  );

const getReturnsList = async (userJWT) =>
  await fetchWrapped(
    cartGraphQLApi,
    new PostOptions({
      bodyObject: {
        query: `query ProductReturns {
            returns {
              products {
                id
                product {
                  id
                  name
                  picture
                  product
                  returnableQuantity
                  updatedAt
                  perItem
                  size
                  pdpPath
                }
                quantity
              }
              createdAt
              reqID
              status
              shopName
              orderID
            }
          }`,
      },
      headers: applyModifiers({}, [addContentTypeJson, addUserJWT(userJWT)]),
    })
  );

const getAddressesList = async (userJWT) =>
  await fetchWrapped(
    cartGraphQLApi,
    new PostOptions({
      bodyObject: {
        query: `query GetAddresses {
          addresses {
            data
            publicId
            name
            default
          }
        }`,
      },
      headers: applyModifiers({}, [addContentTypeJson, addUserJWT(userJWT)]),
    })
  );

const declareReturn = async (orderId, shopId, selectedProducts, returnReason, userJWT, cartSession) =>
  await fetchWrapped(
    urlcat(cartApi, '/returns/declare/'),
    new PostOptions({
      bodyObject: {
        orderId,
        shopId,
        selectedProducts,
        returnReason,
      },
      headers: applyModifiers({}, [addXCartSession(cartSession), addUserJWT(userJWT)]),
    })
  );

export default {
  addProductToCart,
  removeProductFromCart,
  decrementProductQuantity,
  openCarts,
  getDeliveryAddresses,
  selectDeliveryAddress,
  getDeliveryOptions,
  getOrdersList,
  selectShippingMethod,
  updateDeliveryAddress,
  addDeliveryAddress,
  selectBillingAddress,
  declareReturn,
  getReturnsList,
  getAddressesList,
  removeAllProductsFromCart,
  getOrderDetails,
};
