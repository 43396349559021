import urlcat from 'urlcat';
import { fetchWrapped, PostOptions } from './';

import { contentApiV3, contentV3ApiV3, recommendationApi } from './endpoints';

//for SSR:
//@params: reqHeaders: { 'cookie': string, 'user-agent': string, 'x-real-ip': string, 'x-forwarded-for': string, 'x-request-id': string }
//for CSR:
//@params : reqHeaders: null
const getBlogData = async (reqHeaders) =>
  await fetchWrapped(
    urlcat(contentV3ApiV3, '/blog/posts'),
    new PostOptions({ bodyObject: reqHeaders ? { headers: reqHeaders } : {} })
  );
const getHomePageData = async () => await fetchWrapped(urlcat(contentApiV3, '/home-page-data'));
const getLatestHomeData = async () => await fetchWrapped(urlcat(contentApiV3, '/latest-home'));
const getLatestPages = async () => await fetchWrapped(urlcat(contentApiV3, '/latest-pages'));
const getShopInfo = async (shopWebsite) => await fetchWrapped(urlcat(contentApiV3, `/shop/${shopWebsite}`));
const getProductShopInfo = async (shopid) =>
  await fetchWrapped(urlcat(contentApiV3, `/productDetailCardShop/${shopid}`));
const getAllActiveShops = async () => await fetchWrapped(urlcat(contentApiV3, '/shop/all'));
const getVideoData = async (searchTerm) =>
  await fetchWrapped(urlcat(contentApiV3, `/product-videos?query=${searchTerm}`));
//for CSR:
//@params: id: number
//for SSR:
//@params: id: number, reqHeaders: { 'cookie': string, 'user-agent': string, 'x-real-ip': string, 'x-forwarded-for': string, 'x-request-id': string }
const getBlogPostData = async (id, reqHeaders) =>
  await fetchWrapped(
    urlcat(contentV3ApiV3, `/blog/post/`),
    reqHeaders ? new PostOptions({ bodyObject: { id, headers: reqHeaders } }) : new PostOptions({ bodyObject: { id } })
  );
const getDailies = async () => await fetchWrapped(urlcat(contentApiV3, '/dailies'));
const getUserRecommendation = async ({ userJWT, userId }) =>
  await fetchWrapped(`${recommendationApi}/personalized?userId=${userId}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${userJWT}` },
  });

const getMostViewRecommendation = async ({ userId }) =>
  await fetchWrapped(`${recommendationApi}/mostViewedItems?userId=${userId}`);

const getRelatedRecommendation = async ({ userId }) =>
  await fetchWrapped(`${recommendationApi}/relatedViews?userId=${userId}`);

export default {
  getBlogData,
  getHomePageData,
  getBlogPostData,
  getLatestHomeData,
  getLatestPages,
  getShopInfo,
  getAllActiveShops,
  getVideoData,
  getProductShopInfo,
  getDailies,
  getUserRecommendation,
  getMostViewRecommendation,
  getRelatedRecommendation,
};
